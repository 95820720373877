import { useState, useEffect, Children } from "react";
import axios from "axios";

import {
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  FocusLock,
  Form,
  IconButton,
  Container,
  Stack,
  Badge,
  HStack,
  RadioGroup,
  Radio,
  Center,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  Input,
  FormLabel,
  Button
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import Logo from "../components/Logo";
import { Helmet } from "react-helmet";

import Header from "../components/Header";

//<IconButton onClick={() => window.location.href = `/dashboard/edit/${pms.list[index]._id}` } icon={<EditIcon />} />

const ShowPms = ({ pms }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (pms.loaded === 1) {
      setData(pms.list);
    }
  }, [pms]);

  return (
    <TableContainer height="calc(100%-66px)" overflowY="auto">
      <Table size="sm" variant="simple">
        <Thead>
          <Tr>
            <Th>id</Th>
            <Th>PM technique</Th>
            <Th>PM reglementaire</Th>
            <Th>Type de PM</Th>
            <Th>Address</Th>
            <Th>Infrastructure</Th>
            <Th>Latitude</Th>
            <Th>Longitude</Th>
            <Th>More</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data.map((value, index) => {
              return (
                <Tr
                  cursor="pointer"
                  _hover={{ bg: "teal.200" }}
                  onClick={() =>
                    (window.location.href = `/dashboard/edit/${pms.list[index]._id}`)
                  }
                  key={index}
                >
                  <Td>{value._id}</Td>
                  <Td>{value.pm_tech}</Td>
                  <Td>{value.pm_regl}</Td>
                  <Td>{value.pm_kind}</Td>
                  <Td>{value.pm_addr}</Td>
                  <Td>{value.infra_id}</Td>
                  {value.location !== undefined ? (
                    <>
                      <Td>{value.location.coordinates[0]}</Td>
                      <Td>{value.location.coordinates[1]}</Td>
                    </>
                  ) : (
                    <>
                      <Td>Aucune donnée</Td>
                      <Td>Aucune donnée</Td>
                    </>
                  )}
                  <Td>{value.more}</Td>
                </Tr>
              );
            })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Th>id</Th>
            <Th>PM technique</Th>
            <Th>PM reglementaire</Th>
            <Th>Type de PM</Th>
            <Th>Address</Th>
            <Th>Infrastructure</Th>
            <Th>Latitude</Th>
            <Th>Longitude</Th>
            <Th>More</Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

const Dashboard = () => {
  const [pms, setPms] = useState({ loaded: 0, list: [] });
  const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST;

  const getPm = async () => {
    try {
      const response = await axios.get(`${REACT_APP_API_HOST}/pms`, {
        params: { id: "" }
      });
      setPms({ loaded: 1, list: response.data });
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    getPm();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PMZ - Dashboard</title>
        <link rel="canonical" href="https://mypmz.net" />
      </Helmet>
      <Header />
      <Flex height="calc(100vh - 66px)" direction="column">
        <ShowPms pms={pms}></ShowPms>
      </Flex>
    </>
  );
};

export default Dashboard;
