import { useEffect } from "react";
import Home from "./pages/Home";
import Add from "./pages/Add";
import Edit from "./pages/Edit";
import Dashboard from "./pages/Dashboard";
import Listing from "./pages/Listing";
import Header from "./components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import useToken from "./components/useToken";

const App = () => {

  const { token, setToken } = useToken();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PMZ</title>
        <link rel="canonical" href="https://mypmz.net" />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home setToken={setToken}/>} />
          <Route path="/add" element={<Add />} />
          <Route path="/list/:id" element={<Listing />} />
          {token && (
            <>
              <Route path="/dashboard/edit/:id" element={<Edit />} />
              <Route path="/dashboard" element={<Dashboard />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
