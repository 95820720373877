import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import Header from "../components/Header";
import NewPm from "../components/NewPM";
import useToken from "../components/useToken";

const Edit = () => {
  const [pm, setPm] = useState(null);
  const { id: pmId } = useParams();
  const { token, setToken } = useToken();

  let navigate = useNavigate();

  const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST;

  const getPm = async () => {
    try {
      const response = await axios.get(`${REACT_APP_API_HOST}/pm`, {
        params: { id: pmId }
      });
      setPm(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e, formData) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${REACT_APP_API_HOST}/pm`, formData, {
        headers: {
          "x-access-token": token
        }
      });
      const success = response.status === 200;
      if (success) {
        navigate("/dashboard");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (e, formData) => {
    e.preventDefault();
    try {
      const response = await axios.delete(`${REACT_APP_API_HOST}/pm`, {
        params: { id: formData.pm_id },
        headers: {
          "x-access-token": token
        }
      });
      const success = response.status === 200;
      if (success) {
        navigate("/dashboard");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPm();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PMZ - Edit</title>
        <link rel="canonical" href="https://mypmz.net" />
      </Helmet>
      <Header />
      {pm && (
        <NewPm
          handleSubmit={handleSubmit}
          handleDelete={handleDelete}
          data={pm}
          kind="edit"
        ></NewPm>
      )}
    </div>
  );
};

export default Edit;
