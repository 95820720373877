import React from "react";
import { NavLink } from "react-router-dom";
import { Heading } from "@chakra-ui/react";

const Logo = () => {
  return (
    <>
      <NavLink to="/">
        <Heading fontFamily='Readex Pro' color='red.600' fontSize='2em'>PMZ</Heading>
      </NavLink>
    </>
  );
};

export default Logo;
