import { Flex } from "@chakra-ui/react";
import SearchForm from "../components/SearchForm";
import Navigation from "../components/Navigation";
import Logo from "../components/Logo";
import Login from "./Login";

const Header = ({
  search = false,
  nav = false,
  setToken = null,
  setSearchEntry
}) => {
  return (
    <Flex
      padding={["0 0", "0 5vw"]}
      position="sticky"
      columnGap={["2px", "1rem"]}
      alignContent="center"
      justifyContent="space-between"
      alignItems="center"
      h="66px"
      borderBottom="1px solid rgba(0,0,0,0.8)"
      zIndex={999}
      bg="white"
    >
      <Logo />
      <Flex
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        columnGap={["2px", "1rem"]}
      >
        {search && <SearchForm parenSearchEntry={setSearchEntry} />}
        {nav && <Navigation />}
        <Login h={["32px", "44px"]} w={["32px", "44px"]} setToken={setToken} />
      </Flex>
    </Flex>
  );
};

export default Header;
