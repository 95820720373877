import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";
import NewPm from "../components/NewPM";
import Header from "../components/Header";



// const initalText = "6 rue de la joie";

const Add = () => {


  let navigate = useNavigate();

  const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST;

  const handleSubmit = async (e, formData) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${REACT_APP_API_HOST}/pm`, {
        formData
      });
      const success = response.status === 200;
      if (success) {
        navigate("/");
      }
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PMZ - Add 🐇</title>
        <link rel="canonical" href="https://mypmz.net" />
      </Helmet>
      <Header />
      <NewPm handleSubmit={handleSubmit}/>
    </>
  );
};

export default Add;
