import { Children, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useRadio,
  HStack,
  useRadioGroup,
  Img,
  Stack,
  VStack,
  Center,
  Container
} from "@chakra-ui/react";

import MyAutocomplete, {
  MyAutocomplete2,
  MyAutocomplete3
} from "./Autocomplete";
import Bunny from "../components/bunny.svg";

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        bg="#68e1fd"
        boxShadow="md"
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "teal.600"
        }}
        _focus={{
          boxShadow: "outline"
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
}

const RadioGroup = ({
  label = "RadioGroup",
  name,
  handleChange,
  v,
  options
}) => {
  const onChange = radioValue => {
    handleChange({ target: { name: name, value: radioValue } });
  };

  const { setValue, getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    defaultValue: v,
    onChange: onChange
  });

  const group = getRootProps();

  useEffect(() => {
    setValue(v);
  }, [v]);

  return (
    <HStack wrap="wrap" gap="1em" {...group}>
      {options.map(value => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} {...radio}>
            {value}
          </RadioCard>
        );
      })}
    </HStack>
  );
};

const Location = ({ pmLoc }) => {
  const [loc, setLoc] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    if (!pmLoc) return;
    setLoc({ lat: pmLoc.coordinates[0], lng: pmLoc.coordinates[1] });
  }, [pmLoc]);

  return (
    <Flex justifyContent="flex-end" gap="1em" fontSize="sm">
      <Text>latitude: {loc.lat}</Text>
      <Text>Longitude: {loc.lng}</Text>
    </Flex>
  );
};

const MyInput = ({ name, value, handleChange, ...props }) => {
  return (
    <Input
      {...props}
      value={value}
      onChange={e =>
        handleChange({ target: { name: name, value: e.target.value } })
      }
    />
  );
};

const ActionButton = ({ kind, onSubmit, onDelete }) => {
  if (kind === "add") {
    return <Button onClick={onSubmit}>Add PM !</Button>;
  } else {
    return (
      <>
        <Button onClick={onSubmit}>Edit PM !</Button>
        <Button onClick={onDelete}>Delete PM !</Button>
      </>
    );
  }
};

const NewPm = ({ handleSubmit, handleDelete, data = null, kind = "add" }) => {
  const [formData, setFormData] = useState({
    pm_id: -1,
    pm_regl: "",
    pm_tech: "",
    infra_id: "Orange",
    pm_kind: "PME",
    pm_addr: "",
    more_info: "",
    location: null
  });
  const [dataRead, setDataRead] = useState(false);

  const [more, setMore] = useState("");

  let handleMore = e => {
    let inputValue = e.target.value;
    setMore(inputValue);
    setFormData(prevState => ({
      ...prevState,
      more_info: inputValue
    }));
  };
  useEffect(() => {
    setDataRead(true);
    if (!data) return;
    setFormData({
      pm_id: data._id,
      pm_tech: data.pm_tech,
      pm_regl: data.pm_regl,
      infra_id: data.infra_id,
      pm_kind: data.pm_kind,
      pm_addr: data.pm_addr,
      more_info: data.more,
      location: data.location
    });
    setMore(data.more);
  }, [data]);

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSubmit = event => {
    handleSubmit(event, formData);
  };

  const onDelete = event => {
    handleDelete(event, formData);
  };

  return (
    dataRead && (
      <Box marginX={["10px", "60px", "15vw"]} maxWidth="800px">
        <Box
          overflow="hidden"
          position="absolute"
          right="10%"
          top="0%"
          bottom="0%"
          left="50%"
        >
          <Img
            transform="scale(1.8)"
            width="100%"
            height="100%"
            src={Bunny}
            alt="bunny"
          />
        </Box>
        <Flex justifyContent="flex-start" width="100%">
          <Stack
            padding="2em"
            rowGap="1em"
            marginTop="10px"
            borderRadius="10px"
            border="1px solid rgba(0,0,0,0.2)"
          >
            <FormControl>
              <FormLabel>PM technique</FormLabel>
              <MyInput
                name="pm_tech"
                value={formData.pm_tech}
                handleChange={handleChange}
                required={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>PM réglementaire</FormLabel>
              <MyInput
                name="pm_regl"
                value={formData.pm_regl}
                handleChange={handleChange}
                required={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Infrastructure</FormLabel>
              <RadioGroup
                handleChange={handleChange}
                v={formData.infra_id}
                label="Infrastructure"
                name="infra_id"
                options={[
                  "Orange",
                  "Free",
                  "SFR",
                  "Covage / Altitude",
                  "Axione"
                ]}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Type de PM</FormLabel>
              <RadioGroup
                handleChange={handleChange}
                v={formData.pm_kind}
                label="Type de PM"
                name="pm_kind"
                options={["PME", "PMI"]}
              />
            </FormControl>
            <Box>
              <FormControl>
                <FormLabel>Addresse du PM</FormLabel>
                <MyAutocomplete
                  defaultValue={formData.pm_addr}
                  handleChange={handleChange}
                />
                {/* <MyAutocomplete3 handleChange={handleChange} /> */}
              </FormControl>
              <FormControl>
                <Location pmLoc={formData.location} />
              </FormControl>
            </Box>
            <FormControl>
              <FormLabel>Plus d'informations</FormLabel>
              <Textarea value={more} onChange={handleMore} />
            </FormControl>
            <Flex justifyContent="space-around">
              <ActionButton
                kind={kind}
                onDelete={onDelete}
                onSubmit={onSubmit}
              />
            </Flex>
          </Stack>
        </Flex>
      </Box>
    )
  );
};

export default NewPm;
