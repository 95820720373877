import React, { useState } from "react";
import axios from "axios";

import {
  Stack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input
} from "@chakra-ui/react";
import { WarningTwoIcon, CheckIcon, SearchIcon } from "@chakra-ui/icons";

const sortAlphaNum = (a, b) =>
  a.pm_tech.localeCompare(b.pm_tech, "en", { numeric: true });

const SearchForm = ({ parenSearchEntry }) => {
  const [searchEntry, setSearchEntry] = useState("");
  const [response, setResponse] = useState(-1);
  // update search text state
  const updateSearchInput = e => {
    setSearchEntry(e.target.value);
    if (e.target.value === "") {
      setResponse(-1);
      parenSearchEntry([]);
    }
  };

  const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST;

  const handleSubmit = async (e, input) => {
    e.preventDefault();
    if (input === "") return
    try {
      const response = await axios.get(`${REACT_APP_API_HOST}/pms`, {
        params: { id: input }
      });

      const pmFound = response.data;
      const sortedPmFound = pmFound.sort(sortAlphaNum);
      parenSearchEntry(sortedPmFound);
      setResponse(pmFound.length);
    } catch (err) {
      console.log(err);
    }
  };

  const Validation = ({ children }) => {
    if (response < 0) {
      return <></>;
    } else if (response === 0) {
      return (
        <InputRightElement
          h="100%"
          children={<WarningTwoIcon color="red.500" />}
        />
      );
    } else {
      return (
        <InputRightElement
          h="100%"
          children={<CheckIcon color="green.500" />}
        />
      );
    }
  };

  return (
    <Stack spacing={4}>
      <form onSubmit={e => handleSubmit(e, searchEntry)}>
        <InputGroup>
          <InputLeftElement
            h="100%"
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            size={['32px','md','md']}
            onChange={updateSearchInput}
            placeholder="Entrer votre PM"
          />
          <Validation />
        </InputGroup>
      </form>
    </Stack>
  );
};

export default SearchForm;
