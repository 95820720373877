import L from "leaflet";
import { useEffect, useReducer, useState } from "react";
import axios from "axios";
import {
  MapContainer,
  TileLayer,
  useMap,
  Map,
  ZoomControl,
  Marker,
  Popup,
  Circle
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Search from "./Search";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import styles from "./location-button.module.css";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const center = [49.1846919, -0.407262]; // Normandie
// const center = [43.1474654, 6.071378]; // Var

const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST;

const colors = [ "#ff7900", "#cd1e25", "#ff0000", "#032b43", "#136f63"];
const infras = ["OrangE", "FreE", "SFR", "Covage / Altitude", "Axione"];

L.Canvas.include({
  _updateMarker6Point: function (layer) {
    if (!this._drawing || layer._empty()) {
      return;
    }

    var p = layer._point,
      ctx = this._ctx,
      options = layer.options,
      r = Math.max(Math.round(layer._radius), 1);

    // ctx.globalAlpha = options.fillOpacity;

    function mapPoint(x, y, r) {
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x - 0.58 * r, y - r);
      ctx.arc(x, y - 2 * r, r, -Math.PI * 1.161, Math.PI * 0.161);
      ctx.closePath();
    }

    function circle(x, y, r) {
      ctx.beginPath();
      ctx.arc(x, y - 2 * r, (r / 2) * 0.8, 0, Math.PI * 2, false);
      ctx.closePath();

      ctx.fillStyle = "rgba(255, 255, 255, 0.6)";
      
      ctx.fill();
    }

    mapPoint(p.x, p.y, r);
    this._fillStroke(ctx, layer);
    circle(p.x, p.y, r);
  }
});

function getArea(a, b, c) {
  return Math.abs(
    (a[0] * (b[1] - c[1]) + b[0] * (c[1] - a[1]) + c[0] * (a[1] - b[1])) / 2
  );
}

function pointInTriangle(x1, y1, x2, y2, x3, y3, p1, p2) {
  let point = [p1, p2];
  let area = getArea([x1, y1], [x2, y2], [x3, y3]);
  let areaA = getArea([x1, y1], [x2, y2], point);
  let areaB = getArea(point, [x2, y2], [x3, y3]);
  let areaC = getArea([x1, y1], point, [x3, y3]);
  return Math.abs(areaA + areaB + areaC - area) < 0.001;
}

var Marker6Point = L.CircleMarker.extend({
  _updatePath: function () {
    this._renderer._updateMarker6Point(this);
  },
  _updateBounds: function () {
    var r = this._radius,
      r2 = this._radiusY || r,
      w = this._clickTolerance(),
      p = [r + w, r2 + w];
    const pp = this._point.clone();
    pp.y -= r2 * 2;
    this._pxBounds = new L.Bounds(pp.subtract(p), pp.add(p));
  },
  _containsPoint: function (p) {
    const r = this._radius;
    const center = this._point.clone();
    center.y -= 2 * r;
    const o = this._point.clone();
    const p1x = o.x + 0.58 * r;
    const p1y = o.y - r;
    const p2x = o.x - 0.58 * r;
    const p2y = o.y - r;
    const p3x = o.x;
    const p3y = o.y;
    return (
      p.distanceTo(center) <= r + this._clickTolerance() ||
      pointInTriangle(p1x, p1y, p2x, p2y, p3x, p3y, p.x, p.y)
    );
  }
});

const Location = () => {
  const map = useMap();
  const [position, setPosition] = useState(null);

  useEffect(() => {
    map.locate({
      watch: true,
      enableHighAccuracy: true
    });
    map.on("locationfound", handleLocationFound);
  }, [map]);

  const handleLocationFound = event => {
    setPosition(event);
    marker(event.latlng).addTo(map);
  };

  const marker = ({ lat, lng }) => {
    return L.marker([lat, lng], {
      icon: L.divIcon({
        className: styles.locatedAnimation,
        iconSize: L.point(12, 12),
        popupAnchor: [0, -15]
      })
    }).bindPopup("tu es là :)");
  };

  return position ? (
    <>
      <Circle
        center={position.latlng}
        weight={0}
        interactive={false}
        fillColor={"red"}
        fillOpacity={0.1}
        radius={position.accuracy / 2}
      ></Circle>
    </>
  ) : null;
};

const PMcompter = ({ data }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;
    if (data.loaded === 0) {
      return;
    } else {
      const cpt = L.control({ position: "bottomleft" });
      cpt.onAdd = () => {
        const div = L.DomUtil.create("div", "compter");
        div.innerHTML = `${data.list.length}`;
        return div;
      };
      cpt.addTo(map);
    }
  }, [map, data]);
};

const Legend = () => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const legend = L.control({ position: "bottomright" });

    const rows = [];
    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "legend");
      infras.map((infra, index) => {
        return rows.push(`
            <div class="row" style="display:flex; line-height:20px; background-color: white; justify-content: flex-start; align-items:center; padding: 0 10px">
              <div style="background-color: ${
                colors[infras.indexOf(infra)]
              };width:10px;height:10px"></div><span style="margin-left:10px">${infra}</span>
            </div>
          `);
      });
      div.innerHTML = rows.join("");
      return div;
    };

    legend.addTo(map);
  }, [map]);

  return;
};

function infraColor(infra) {
  const idx = infras.indexOf(infra);
  if (idx < 0) {
    return 0;
  }
  return idx;
}

const DisplayMarker = ({ data, selectedIndex, clickedIndex }) => {
  const map = useMap();
  const [markers, setMarkers] = useState([]);

  function customMarkerIcon(color) {
    const svgTemplate = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="marker">
      <path fill-opacity=".25" d="M16 32s1.427-9.585 3.761-12.025c4.595-4.805 8.685-.99 8.685-.99s4.044 3.964-.526 8.743C25.514 30.245 16 32 16 32z"/>
      <path fill="${color}" stroke="#fff" d="M15.938 32S6 17.938 6 11.938C6 .125 15.938 0 15.938 0S26 .125 26 11.875C26 18.062 15.938 32 15.938 32zM16 6a4 4 0 100 8 4 4 0 000-8z"/>
    </svg>`;

    const icon = new L.DivIcon({
      className: "marker",
      html: svgTemplate,
      iconSize: [40, 40],
      iconAnchor: [12, 24],
      popupAnchor: [7, -16]
    });

    return icon;
  }

  const popup = pm => {
    if (!pm.location) return;
    const p = L.popup({
      offset: [0, -30]
    })
      .setLatLng([pm.location.coordinates[0], pm.location.coordinates[1]])
      .setContent(`<a href="list/${pm._id}">${pm.pm_regl} / ${pm.pm_tech}</a>`);
    return p;
  };

  const marker = pm => {
    if (pm.location === undefined) return;

    const radius = 12;
    return new Marker6Point(
      [pm.location.coordinates[0], pm.location.coordinates[1]],
      {
        stroke: true,
        weight: 2,
        color: "white",
        fillColor: colors[infraColor(pm.infra_id)],
        radius: radius,
        fillOpacity: 0.9, 
      }
    );

    // return L.marker([pm.location.coordinates[0], pm.location.coordinates[1]], {
    //   key: pm._id,
    //   icon: customMarkerIcon(colors[infraColor(pm.infra_id)])
    // }).bindPopup(`<a href="list/${pm._id}">${pm.pm_regl} / ${pm.pm_tech}</a>`);
  };

  useEffect(() => {
    if (!map) return;
    if (selectedIndex === -1) {
      markers.forEach(m => {
        if (m.marker) {
          m.marker.setStyle({ fillColor: colors[infraColor(m.pm.infra_id)] });
        }
      });
    } else {
      // const m = markers.filter(marker => marker.id === selectedIndex)[0];
      markers.forEach(m => {
        if (m.marker) {
          if (m.pm._id !== selectedIndex) {
            m.marker.setStyle({ fillColor: "grey" });
          } else {
            m.marker.setStyle({ fillColor: colors[infraColor(m.pm.infra_id)] });
          }
        }
      });
    }
  }, [map, selectedIndex]);

  useEffect(() => {
    if (!map) return;

    const m = markers.filter(marker => marker.pm._id === clickedIndex)[0];
    if (m && m.marker) {
      map.flyToBounds([m.marker._latlng], { maxZoom: 14 });
      popup(m.pm).openOn(map);
    }
  }, [map, clickedIndex]);

  useEffect(() => {
    if (!map) return;
    if (data.loaded === 0) {
      return;
    }


    data.list.forEach(pm => {
      const m = marker(pm);
      if (m) {
        setMarkers(previous => [...previous, { pm: pm, marker: m }]);
        m.bindPopup(
          `<a href="list/${pm._id}">${pm.pm_regl} / ${pm.pm_tech}</a>`
        );
        m.addTo(map);
      }
    });
  }, [map, data]);

  return null;
};

const MapWrapper = ({ searchTerm }) => {
  const [selected, setSelected] = useState();
  const [clicked, setClicked] = useState();
  const [pmLocation, setPmLocation] = useState({ loaded: 0, list: [] });
  const [allPM, setAllPm] = useState({ loaded: 0, list: [] });

  function handleItemEnter(index) {
    setSelected(index);
  }

  function handleItemLeave(index) {
    setSelected(-1);
  }

  function handleItemClick(index) {
    setClicked(index);
  }

  const getAllPm = async () => {
    try {
      const response = await axios.get(`${REACT_APP_API_HOST}/pms`, {
        params: { id: "" }
      });
      const allPm = response.data;
      const PmOnlyLocation = allPm.filter(pm => (pm.location !== undefined));
      PmOnlyLocation.sort((a,b) => {
        return b.location.coordinates[0] - a.location.coordinates[0]
      })
      setPmLocation({ loaded: 1, list: PmOnlyLocation });
      setAllPm({ loaded: 1, list: allPm })
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPm();
  }, []);

  return (
    <>
      <div className="container-map">
        {searchTerm && (
          <Search
            searchTerm={searchTerm}
            onItemEnter={handleItemEnter}
            onItemLeave={handleItemLeave}
            onItemClick={handleItemClick}
          />
        )}
        <MapContainer
          zoomControl={false}
          center={center}
          zoom={10}
          scrollWheelZoom={true}
          preferCanvas={true}
          
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
            maxZoom={20}
            detectRetina={true}
          />
          <ZoomControl position={"topright"} />
          <DisplayMarker
            data={pmLocation}
            selectedIndex={selected}
            clickedIndex={clicked}
          />
          <Location />
          <Legend />
          <PMcompter data={allPM} />
        </MapContainer>
      </div>
    </>
  );
};

export default MapWrapper;
