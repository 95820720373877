import { useState, useEffect } from "react";

export default function useToken() {
  const [token, setToken] = useState(sessionStorage.getItem("token"));
  const saveToken = userToken => {
    sessionStorage.setItem("token", userToken);
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
