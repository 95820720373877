import React from "react";

const Link = ({ pm, onItemClick, onItemLeave, onItemEnter }) => {
  const onEnter = e => {
    onItemEnter(pm._id);
  };

  const onLeave = e => {
    onItemLeave(pm._id);
  };

  const onClick = e => {
    if (pm.location) {
      e.preventDefault();
    }
    onItemClick(pm._id);
  };

  if (pm.location) {
    return (
      <span
        className="with_loc"
        onPointerEnter={onEnter}
        onPointerLeave={onLeave}
        onClick={onClick}
      >
        {pm.pm_tech} / {pm.pm_regl}
      </span>
    );
  } else {
    return (
      <a
        onPointerEnter={onEnter}
        onPointerLeave={onLeave}
        onClick={onClick}
        className="without_loc"
        href={"list/" + pm._id}
      >
        {pm.pm_tech} / {pm.pm_regl}
      </a>
    );
  }
};

const Search = ({ searchTerm, onItemEnter, onItemLeave, onItemClick }) => {
  return (
    <div className="wrapper">
      {searchTerm.length > 0 && (
        <div className="info">
          <ul>
            {searchTerm.map((i, index) => (
              <li key={i._id}>
                <Link
                  pm={i}
                  onItemClick={onItemClick}
                  onItemLeave={onItemLeave}
                  onItemEnter={onItemEnter}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Search;
