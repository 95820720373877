import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Header from "../components/Header";

const Listing = () => {
  const [pm, setPm] = useState(null);
  const { id: pmId } = useParams();

  const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST;

  const getPm = async () => {
    try {
      const response = await axios.get(`${REACT_APP_API_HOST}/pm`, {
        params: { id: pmId }
      });
      setPm(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPm();
  }, []);

  return (
    <>
      <Header />
      {pm && (
        <div className="details">
          <h1>Description</h1>
          <div className="desc">
            <dl>
              <dt>Addresse</dt>
              <dd>{pm.pm_addr}</dd>
              <dt>Date</dt>
              <dd>
                {new Date(pm.created_at)
                  .toISOString()
                  .replace(/T/, " ")
                  .replace(/\..+/, "")}
              </dd>
              <dt>PM</dt>
              <dd>{pm.pm_kind}</dd>
              <dt>Infrastructure</dt>
              <dd>{pm.infra_id}</dd>
              {/* <dt>Createur</dt>
              <dd>{pm.user}</dd> */}
              <dt>Identifiant Réglementaire</dt>
              <dd>{pm.pm_regl}</dd>
              <dt>Identifiant Technique</dt>
              <dd>{pm.pm_tech}</dd>
              <dt>Description</dt>
              <dd>{pm.more}</dd>
              {pm.location && (
                <>
                  <dt>Latitude / Longitude</dt>
                <dd>{pm.location.coordinates[0]} / {pm.location.coordinates[1]}</dd>
                </>
              )}
            </dl>
          </div>
        </div>
      )}
    </>
  );
};

export default Listing;
