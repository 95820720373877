import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import { AddIcon, EditIcon } from "@chakra-ui/icons";

const Navigation = () => {
  return (
    <Flex columnGap={["2px", "1rem"]}>
      <NavLink to="/add">
        <Flex
          h={["26px", "44px"]}
          w={["26px", "44px"]}
          bg="blue.900"
          alignItems="center"
          justifyContent="center"
          borderRadius="3px"
        >
          <AddIcon color="white" />
        </Flex>
      </NavLink>
      <NavLink to="/dashboard">
        <Flex
          h={["26px", "44px"]}
          w={["26px", "44px"]}
          bg="green.600"
          alignItems="center"
          justifyContent="center"
          borderRadius="3px"
        >
          <EditIcon color="white" />
        </Flex>
      </NavLink>
    </Flex>
  );
};

export default Navigation;
