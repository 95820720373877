import { useState, useEffect } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import useOnclickOutside from "react-cool-onclickoutside";

import { OpenStreetMapProvider, GeoApiFrProvider } from "leaflet-geosearch";

import { Loader } from "@googlemaps/js-api-loader";

import { Box, Input, ListItem, UnorderedList, VStack } from "@chakra-ui/react";
import axios from "axios";

//const provider = new OpenStreetMapProvider();
const provider = new GeoApiFrProvider();

const REACT_APP_API_GOOGLE_MAP = process.env.REACT_APP_API_GOOGLE_MAP;

const MyAutocomplete = ({
  defaultValue = "7 Boulevard du Général de Gaulle, Paris, France",
  handleChange
}) => {
  const [input, setInput] = useState(defaultValue);

  const onChange = e => {
    setInput(e.target.value);
  };

  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: REACT_APP_API_GOOGLE_MAP,
    language: "fr",
    options: {
      types: ["address"],
      componentRestrictions: { country: "fr" }
    },
    defaultValue: { defaultValue },
    debounce: 1000,
    onPlaceSelected: place => {
      const loc = {
        type: "Point",
        coordinates: [
          place.geometry.location.lat(),
          place.geometry.location.lng()
        ]
      };
      handleChange({
        target: { name: "pm_addr", value: place.formatted_address }
      });
      handleChange({ target: { name: "location", value: loc } });
      setInput(place.formatted_address);
    }
  });

  return <Input value={input} onChange={onChange} ref={ref} />;
};

const MyAutocomplete2 = ({
  defaultValue = "7 Boulevard du Général de Gaulle, Paris, France",
  handleChange
}) => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading
  } = usePlacesService({
    apiKey: REACT_APP_API_GOOGLE_MAP
  });

  const test = e => {
    console.log(e);
  };

  const [input, setInput] = useState(defaultValue);

  // const savePlaceDetailsToState = place => {
  //   console.log("value found", place);
  //   setInput(place.formatted_address);
  // };

  // useEffect(() => {
  //   // fetch place details for the first element in placePredictions array
  //   console.log("place prediction", placePredictions);
  //   if (placePredictions.length)
  //     placesService?.getDetails(
  //       {
  //         placeId: placePredictions[0].place_id
  //       },
  //       placeDetails => savePlaceDetailsToState(placeDetails)
  //     );
  // }, [placePredictions]);

  return (
    <>
      <Input
        position="relative"
        value={input}
        onClick={test}
        onChange={evt => {
          getPlacePredictions({ input: evt.target.value });
          setInput(evt.target.value);
        }}
        loading={isPlacePredictionsLoading}
      />
      {!isPlacePredictionsLoading && (
        <VStack
          spacing={0}
          bg="white"
          width="100%"
          position="absolute"
          border="1px solid rgba(0,0,0,0.8)"
          zIndex={999}
        >
          {placePredictions.map(item => {
            return (
              <Box
                paddingLeft="2rem"
                width="100%"
                lineHeight="2rem"
                borderBottom="1px solid rgba(0,0,0,0.2)"
                bg="white"
              >
                {item.description}
              </Box>
            );
          })}
        </VStack>
      )}
    </>
  );
};

const MyAutocomplete3 = ({
  defaultValue = "7 Boulevard du Général de Gaulle, Paris, France",
  handleChange
}) => {
  const { loading, place, findPlace, clearPlace } = useGoogleMap({
    apiKey: REACT_APP_API_GOOGLE_MAP
  });
  const [input, setInput] = useState(defaultValue);
  const ref = useOnclickOutside(() => {
    clearPlace("");
  });

  return (
    <>
      <form
        onSubmit={evt => {
          evt.preventDefault();
          findPlace({ address: input });
        }}
      >
        <Input
          position="relative"
          value={input}
          onChange={evt => {
            setInput(evt.target.value);
          }}
        />
      </form>
      {place.length > 0 && (
        <VStack
          ref={ref}
          spacing={0}
          bg="white"
          width="100%"
          position="absolute"
          border="1px solid rgba(0,0,0,0.2)"
          boxShadow="2xl"
          fontSize="sm"
          zIndex={999}
        >
          {place?.map((item, index) => {
            return (
              <Box
                onClick={e => {
                  setInput(item.label);
                  console.log(item.x, item.y, item.label);
                  const loc = {
                    type: "Point",
                    coordinates: [item.x, item.y]
                  };
                  handleChange({
                    target: { name: "pm_addr", value: item.label }
                  });
                  handleChange({
                    target: { name: "location", value: loc }
                  });
                  clearPlace("");
                }}
                _hover={{ bg: "teal.200", cursor: "pointer" }}
                key={index}
                paddingLeft="2rem"
                width="100%"
                lineHeight="2rem"
                borderBottom="1px solid rgba(0,0,0,0.2)"
                bg="white"
              >
                {item.label}
              </Box>
            );
          })}
        </VStack>
      )}
    </>
  );
};

export default MyAutocomplete;

const useGoogleMap = ({ apiKey }) => {
  const [place, setPlace] = useState({});
  const [loading, setLoading] = useState(-1);
  const [localApiKey, setLocalApiKey] = useState();

  useEffect(() => {
    setLocalApiKey(apiKey);
  }, [apiKey]);

  const clearPlace = () => {
    setPlace({});
  };

  const findPlace = async ({ address }) => {
    console.log(address);

    try {
      console.log("send adresse: ", address);
      setLoading(true);
      const response = await provider.search({ query: address });
      console.log(response);
      setPlace(response);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return {
    loading,
    place,
    findPlace,
    clearPlace
  };
};

export { MyAutocomplete2, MyAutocomplete3, useGoogleMap };
