import { useEffect, useState } from "react";
import Header from "../components/Header";

import MapWrapper from "../components/Map";

import { Helmet } from "react-helmet";

const Home = ({setToken}) => {
  const [searchEntry, setSearchEntry] = useState("");
  const [login, setLogin] = useState("");


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PMZ - Home</title>
        <link rel="canonical" href="https://mypmz.net" />
      </Helmet>
      <Header setToken={setToken} search={true} nav={true} setSearchEntry={setSearchEntry} />
      <MapWrapper searchTerm={searchEntry} />
    </div>
  );
};

export default Home;
