import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  AvatarGroup,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import axios from "axios";
import useToken from "./useToken";

const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST;

const LoginContent = ({ handleChange }) => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <VStack>
      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input
          onChange={e =>
            handleChange({ target: { name: "email", value: e.target.value } })
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel>Password</FormLabel>
        <InputGroup>
          <Input
            type={show ? "text" : "password"}
            onChange={e =>
              handleChange({
                target: { name: "password", value: e.target.value }
              })
            }
          />
          <InputRightElement width="4.5rem">
            <Button margin="0 1rem" h="1.75rem" size="sm" onClick={handleClick}>
              {show ? "Hide" : "Show"}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </VStack>
  );
};

const ModalLogin = ({ isOpen, onClose, setToken }) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleLogin = async e => {
    e.preventDefault();
    try {
      const post = { email: email, password: password };
      const response = await axios.post(`${REACT_APP_API_HOST}/login`, post);
      const success = response.status === 200;
      if (success) {
        setToken(response.data);
        onClose();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    console.log(e, name, value);
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Login</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <LoginContent handleChange={handleChange} />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleLogin}>
            LogIn
          </Button>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const Login = ({ setToken, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <AvatarGroup size={['sm','md']} spacing="1rem">
        <Avatar
        
          cursor="pointer"
          onClick={onOpen}
          //   name="kozo"
          //   src="https://avatarfiles.alphacoders.com/195/195926.jpg"
        />
      </AvatarGroup>
      {setToken !== null && (
        <ModalLogin setToken={setToken} isOpen={isOpen} onClose={onClose} />
      )}
    </>
  );
};

export default Login;
